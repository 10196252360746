.salesHub {
  &.submitDeal-options {
    flex: 1 1 0%;
    @media screen and (max-width: 1300px) {
      &.submitDeal-options {
        overflow: hidden;
        flex: auto;
        width: 100%;
      }
    }
  }
  &.submitDeal-modalWidth {
    width: 30%;
    @media screen and (max-width: 1300px) {
      &.submitDeal-modalWidth {
        width: 60%;
      }
    }
  }
  &.submitDeal-button {
    flex-direction: row;
    @media screen and (max-width: 600px) {
      &.submitDeal-button {
        flex-direction: column;
        margin-left: 30px;
      }
    }
  }
  &.pricingStats {

    border-radius: 5px;

    &.container {
      width: 100%;
      padding-bottom: 10px;
      margin-left: 2px;
    }

    position: sticky;
    transition: 400ms bottom;

    bottom: 0px;
    opacity: 1;
    z-index: 2;
    visibility: visible;

    &.arrow-up {

      position: relative;

      min-width: 100px;
      border-radius: 12px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      background: rgba($secondary, 0.2);

      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.9px);

      &:hover
      {
        transition: 800ms all;
        background: rgba($secondary, 0.4);
      }

    }

    &.stat-card {

      &.container {
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 5px;
        padding-bottom: 5px;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        @media screen and (max-width: 1200px) {
          &.container {
            flex-direction: column;
          }
        }
      }

      position: relative;

      margin-left: 6px;
      margin-right: 8px;

      display: flex;
      flex: 1 1;
      flex-wrap: wrap;
      flex-direction: row;
      background-color: white;
      border-radius: 4px;
      padding: 0px;

      background: rgba($secondary, 0.2);

      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10.9px);

    }

    &.arrow-up.hidden {
      visibility: hidden;
      height: 0px;
    }

    &.pricingStats.hidden {
      position: relative;
      bottom: auto;
    }

    &.pricingStats.noPadding {
      padding-bottom: 0px;
    }

    &.pricingStats.closed {
      bottom: -100px;
      @media screen and (max-width: 1200px) {
        &.pricingStats {
          bottom: -280px;
        }
      }
    }
  }
}

