.modalBackground {

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    .modalContent {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
    }
    .modalDealContent {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
    }
}


.modalTimeout {

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 10, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modalContent {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
  }
  .modalDealContent {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
  }
}


.productConfiguratorModal {

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;


  .productConfiguratorModalContent {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
  }
}


.genericModalContainer {

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;


  .genericModalContent {
    background-color: white;
    padding: 10px;
    border-radius: 3px;
  }

  .genericaModalChild {
    &.genericaModalChild:first-child {
      height: 20%;
      border-radius: 0px;
    }
    
    &.genericaModalChild:not(:first-child) {
      height: 80%;
      width: 100%;
      border-radius: 0px;
    }
  }
}


.addQuoteModalContainer {

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;


  .addQuoteModalContent {
    background-color: white;
    overflow: auto;
    padding: 10px;
    border-radius: 8px;
  }
}


