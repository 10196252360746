.tableContainerL {

  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  .clickable:hover {
    background-color: lightgrey;
    transition: 0.3s;
  }
  
}

.mainTableL {
    width: 100%;
    flex: 0;
    font-size: 12px;
    border-left: 1px solid $lightGrey;
    border-top: 1px solid $lightGrey;

    th,
    td {
        background-color: rgba(#EFEFEF, 0.2);
        border-right: 1px solid $lightGrey;
        border-bottom: 1px solid $lightGrey;
        padding: 6px 8px 6px 8px;
    }

    thead {
        background-color: white;
    }

    th {
        font-size: 10px;
        text-align: left;
        position: relative;
        background-color: rgba(#EFEFEF, 0.6);
        border-top: 1px solid $lightGrey;
        user-select: none;
        vertical-align: top;
    }

    .sortLabel {
        display: inline;
        float: right;
    }

    .filterIcon {
        display: inline;
        font-size: 12px;

        &.active {
            span {
                color: $green;
            }
        }
    }

    &.small {

        th,
        td {
            padding: 2px 3px;
        }

        td {
            font-size: 12px;
        }
    }


}

.columnFilterHolderL {
    position: absolute;
    z-index: 0;
}

.filtersHolderL {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .filter {
        padding: 5px 8px;
        margin-right: 5px;
        background-color: $primary;
        color: white;
        border-radius: 10px;
        font-size: 12px;
        display: flex;

        .clear {
            span {
                color: white;
            }

            cursor: pointer;
            margin-left: 5px;

        }
    }
}


.tableLowerContainerL {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  flex-wrap: nowrap;
  height: 5%;
  width: 100%;
}


.totalRecordsL {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.fixed {
        margin-top: -25px;
    }
}

.paginationL {
    margin-top: 14px;
    display: flex;
    justify-content: center; 
    justify-content: flex-start;
    
    .pageFirst,
    .pageLast,
    .pageForward,
    .pageBack {
        cursor: pointer;
    }

    .pageBack {
        margin-left: 5px
    }

    .pageForward {
        margin-right: 5px
    }

    .pageLast,
    .pageFirst {
        .second {
            margin-left: -7px;
        }
    }

    .disabled {
        cursor: not-allowed;
        color: grey;
    }

    .pages {
        margin: 0 10px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        text-align: center;

        .page {
            cursor: pointer;
            min-width: 20px;
            padding: 0 5px;

            &.selected {
                color: green;
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
  
    @media screen and (max-width: 600px) {
      &.paginationL {
        justify-content: flex-start;
      }
    }
}