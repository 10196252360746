.cardGeneric {

  display: flex;
  flex: 1 1 0px;
  flex-wrap: wrap;
  flex-direction: row;

  background-color: white;
  border-radius: 4px;
  margin: 4px;
  padding: 0px;

  @media screen and (max-width: 1200px) {
    &.cardGeneric {
      flex: auto;
      width: 100%;
    }
  }

  &.clickable:hover {
    background-color: lightgrey;
    transition: 0.3s;
  }

  &.cardChild {

    flex-direction: column;
    flex-basis: 100%;

    background-color: transparent;
    pointer-events: none;
    text-align: center;
    width: 100%;
    margin: 6px;

    &.cardChild:first-child {
      flex-grow: 1;
    }
    
    &.cardChild:not(:first-child) {
      flex-grow: 0;
      justify-content: flex-end;
      padding: 2px 0px 10px 0px;
    }

  }

  &.pointer-events-all {
    pointer-events: all;
  }
  

  &.cardSubHeading {
    &.cardChild:not(:first-child) {
      justify-content: flex-start;
      padding: 0;
    }
  }

  .cardDataRow {
    display: flex;
    flex: 1;
    .cardDataColumn {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
    }
  }

  &.blue { background-color:rgba($blue, .7); }
  &.green { background-color:rgba($green, .9); }
  &.red { background-color:rgba($red, .8); }
  &.purple { background-color:rgba($purple, .8); }
  &.orange { background-color:rgba($orange, .8); }
  &.grey { background-color:rgba($grey, .8); }
  &.lightGrey { background-color:rgba($lightGrey, .8); }
}