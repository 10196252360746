* {
    font-family: 'Poppins', sans-serif;
    color: $default;
    box-sizing: border-box;
}

.subHeaderContent {
  background-color: $background;
  border-radius: 10px;
}

.lock-scroll { overflow: hidden; }
.unlock-scroll { overflow: auto; }

.mar-0 { margin: 0px !important; }
.mar-1 { margin: 1px !important; }
.mar-2 { margin: 2px !important; }
.mar-3 { margin: 3px !important; }
.mar-4 { margin: 4px !important; }
.mar-5 { margin: 5px !important; }
.mar-6 { margin: 6px !important; }
.mar-7 { margin: 7px !important; }
.mar-8 { margin: 8px !important; }
.mar-9 { margin: 9px !important; }
.mar-10 { margin: 10px !important; }
.mar-11 { margin: 11px !important; }
.mar-12 { margin: 12px !important; }
.mar-13 { margin: 13px !important; }
.mar-14 { margin: 14px !important; }
.mar-15 { margin: 15px !important; }
.mar-16 { margin: 16px !important; }
.mar-17 { margin: 17px !important; }
.mar-18 { margin: 18px !important; }
.mar-19 { margin: 19px !important; }
.mar-20 { margin: 20px !important; }
.mar-b0 { margin-bottom: 0px !important; }
.mar-b1 { margin-bottom: 1px !important; }
.mar-b2 { margin-bottom: 2px !important; }
.mar-b3 { margin-bottom: 3px !important; }
.mar-b4 { margin-bottom: 4px !important; }
.mar-b5 { margin-bottom: 5px !important; }
.mar-b10 { margin-bottom: 10px !important; }
.mar-b15 { margin-bottom: 15px !important; }
.mar-b20 { margin-bottom: 20px !important; }
.mar-b25 { margin-bottom: 25px !important; }
.mar-b30 { margin-bottom: 30px !important; }
.mar-b40 { margin-bottom: 40px !important; }
.mar-l5 { margin-left: 5px !important; }
.mar-l10 { margin-left: 10px !important; }
.mar-l15 { margin-left: 15px !important; }
.mar-l20 {  margin-left: 20px !important; }
.mar-r5 { margin-right: 5px !important; }
.mar-r10 { margin-right: 10px !important; }
.mar-r15 { margin-right: 15px !important; }
.mar-r20 { margin-right: 20px !important; }
.mar-r30 { margin-right: 30px !important; }
.mar-t5 { margin-top: 5px !important; }
.mar-t10 { margin-top: 10px !important; }
.mar-t15 { margin-top: 15px !important; }
.mar-t20 { margin-top: 20px !important; }

.pad-0 { padding: 0px !important; }
.pad-1 { padding: 1px !important; }
.pad-2 { padding: 2px !important; }
.pad-3 { padding: 3px !important; }
.pad-4 { padding: 4px !important; }
.pad-5 { padding: 5px !important; }
.pad-6 { padding: 5px !important; }
.pad-7 { padding: 5px !important; }
.pad-8 { padding: 5px !important; }
.pad-9 { padding: 5px !important; }
.pad-10 { padding: 10px !important; }
.pad-15 { padding: 15px !important; }
.pad-20 { padding: 20px !important; }
.pad-30 { padding: 30px !important; }
.pad-40 { padding: 40px !important; }
.pad-50 { padding: 50px !important; }


.mar-t1 { margin-top: 1px !important; }
.mar-t2 { margin-top: 2px !important; }
.mar-t3 { margin-top: 3px !important; }
.mar-t4 { margin-top: 4px !important; }
.mar-t5 { margin-top: 5px !important; }
.mar-t6 { margin-top: 6px !important; }
.mar-t7 { margin-top: 7px !important; }
.mar-t8 { margin-top: 8px !important; }
.mar-t9 { margin-top: 9px !important; }
.mar-t10 { margin-top: 10px !important; }

.mar-r1 { margin-right: 1px !important; }
.mar-r2 { margin-right: 2px !important; }
.mar-r3 { margin-right: 3px !important; }
.mar-r4 { margin-right: 4px !important; }
.mar-r5 { margin-right: 5px !important; }
.mar-r6 { margin-right: 6px !important; }
.mar-r7 { margin-right: 7px !important; }
.mar-r8 { margin-right: 8px !important; }
.mar-r9 { margin-right: 9px !important; }
.mar-r10 { margin-right: 10px !important; }

.mar-b1 { margin-bottom: 1px !important; }
.mar-b2 { margin-bottom: 2px !important; }
.mar-b3 { margin-bottom: 3px !important; }
.mar-b4 { margin-bottom: 4px !important; }
.mar-b5 { margin-bottom: 5px !important; }
.mar-b6 { margin-bottom: 6px !important; }
.mar-b7 { margin-bottom: 7px !important; }
.mar-b8 { margin-bottom: 8px !important; }
.mar-b9 { margin-bottom: 9px !important; }
.mar-b10 { margin-bottom: 10px !important; }
.mar-b20 { margin-bottom: 20px !important; }
.mar-b30 { margin-bottom: 30px !important; }
.mar-b40 { margin-bottom: 40px !important; }
.mar-b50 { margin-bottom: 50px !important; }

.mar-l1 { margin-left: 1px !important; }
.mar-l2 { margin-left: 2px !important; }
.mar-l3 { margin-left: 3px !important; }
.mar-l4 { margin-left: 4px !important; }
.mar-l5 { margin-left: 5px !important; }
.mar-l6 { margin-left: 6px !important; }
.mar-l7 { margin-left: 7px !important; }
.mar-l8 { margin-left: 8px !important; }
.mar-l9 { margin-left: 9px !important; }
.mar-l10 { margin-left: 10px !important; }
.mar-l20 { margin-left: 20px !important; }
.mar-l30 { margin-left: 30px !important; }


.pad-t1 { padding-top: 1px !important; }
.pad-t2 { padding-top: 2px !important; }
.pad-t3 { padding-top: 3px !important; }
.pad-t4 { padding-top: 4px !important; }
.pad-t5 { padding-top: 5px !important; }
.pad-t6 { padding-top: 6px !important; }
.pad-t7 { padding-top: 7px !important; }
.pad-t8 { padding-top: 8px !important; }
.pad-t9 { padding-top: 9px !important; }
.pad-t10 { padding-top: 10px !important; }
.pad-t20 { padding-top: 20px !important; }
.pad-t30 { padding-top: 30px !important; }
.pad-t40 { padding-top: 40px !important; }
.pad-t50 { padding-top: 50px !important; }
.pad-t60 { padding-top: 60px !important; }
.pad-t70 { padding-top: 70px !important; }
.pad-t80 { padding-top: 80px !important; }
.pad-t90 { padding-top: 90px !important; }
.pad-t100 { padding-top: 100px !important; }

.pad-r1 { padding-right: 1px !important; }
.pad-r2 { padding-right: 2px !important; }
.pad-r3 { padding-right: 3px !important; }
.pad-r4 { padding-right: 4px !important; }
.pad-r5 { padding-right: 5px !important; }
.pad-r6 { padding-right: 6px !important; }
.pad-r7 { padding-right: 7px !important; }
.pad-r8 { padding-right: 8px !important; }
.pad-r9 { padding-right: 9px !important; }
.pad-r10 { padding-right: 10px !important; }
.pad-r20 { padding-right: 20px !important; }
.pad-r30 { padding-right: 30px !important; }
.pad-r40 { padding-right: 40px !important; }
.pad-r50 { padding-right: 50px !important; }
.pad-r60 { padding-right: 60px !important; }
.pad-r70 { padding-right: 70px !important; }
.pad-r80 { padding-right: 80px !important; }
.pad-r90 { padding-right: 90px !important; }
.pad-r100 { padding-right: 100px !important; }

.pad-b1 { padding-bottom: 1px !important; }
.pad-b2 { padding-bottom: 2px !important; }
.pad-b3 { padding-bottom: 3px !important; }
.pad-b4 { padding-bottom: 4px !important; }
.pad-b5 { padding-bottom: 5px !important; }
.pad-b6 { padding-bottom: 6px !important; }
.pad-b7 { padding-bottom: 7px !important; }
.pad-b8 { padding-bottom: 8px !important; }
.pad-b9 { padding-bottom: 9px !important; }
.pad-b10 { padding-bottom: 10px !important; }
.pad-b20 { padding-bottom: 20px !important; }
.pad-b30 { padding-bottom: 30px !important; }
.pad-b40 { padding-bottom: 40px !important; }
.pad-b50 { padding-bottom: 50px !important; }
.pad-b60 { padding-bottom: 60px !important; }
.pad-b70 { padding-bottom: 70px !important; }
.pad-b80 { padding-bottom: 80px !important; }
.pad-b90 { padding-bottom: 90px !important; }
.pad-b100 { padding-bottom: 100px !important; }

.pad-l1 { padding-left: 1px !important; }
.pad-l2 { padding-left: 2px !important; }
.pad-l3 { padding-left: 3px !important; }
.pad-l4 { padding-left: 4px !important; }
.pad-l5 { padding-left: 5px !important; }
.pad-l6 { padding-left: 6px !important; }
.pad-l7 { padding-left: 7px !important; }
.pad-l8 { padding-left: 8px !important; }
.pad-l9 { padding-left: 9px !important; }
.pad-l10 { padding-left: 10px !important; }
.pad-l20 { padding-left: 20px !important; }
.pad-l30 { padding-left: 30px !important; }
.pad-l40 { padding-left: 40px !important; }
.pad-l50 { padding-left: 50px !important; }
.pad-l60 { padding-left: 60px !important; }
.pad-l70 { padding-left: 70px !important; }
.pad-l80 { padding-left: 80px !important; }
.pad-l90 { padding-left: 90px !important; }
.pad-l100 { padding-left: 100px !important; }


.height-100 { height: 100%; }
.height-95 { height: 95%; }
.height-90 { height: 90%; }
.height-85 { height: 85%; }
.height-80 { height: 80%; }
.height-75 { height: 75%; }
.height-70 { height: 70%; }
.height-65 { height: 65%; }
.height-60 { height: 60%; }
.height-55 { height: 55%; }
.height-50 { height: 50%; }
.height-45 { height: 45%; }
.height-40 { height: 40%; }
.height-35 { height: 35%; }
.height-30 { height: 30%; }
.height-25 { height: 25%; }
.height-20 { height: 20%; }
.height-15 { height: 15%; }
.height-10 { height: 10%; }
.height-5 { height: 5%; }

.height-500-px { height: 500px; }
.height-450-px { height: 450px; }
.height-400-px { height: 400px; }
.height-350-px { height: 350px; }
.height-300-px { height: 300px; }
.height-250-px { height: 250px; }
.height-200-px { height: 200px; }
.height-150-px { height: 150px; }
.height-100-px { height: 100px; }
.height-95-px { height: 95px; }
.height-90-px { height: 90px; }
.height-85-px { height: 85px; }
.height-80-px { height: 80px; }
.height-75-px { height: 75px; }
.height-70-px { height: 70px; }
.height-65-px { height: 65px; }
.height-60-px { height: 60px; }
.height-55-px { height: 55px; }
.height-50-px { height: 50px; }
.height-45-px { height: 45px; }
.height-40-px { height: 40px; }
.height-35-px { height: 35px; }
.height-30-px { height: 30px; }
.height-25-px { height: 25px; }
.height-22-px { height: 22px; }
.height-20-px { height: 20px; }
.height-15-px { height: 15px; }
.height-10-px { height: 10px; }
.height-5-px { height: 5px; }

.max-height-800-px { max-height: 800px; }
.max-height-700-px { max-height: 700px; }
.max-height-600-px { max-height: 600px; }
.max-height-500-px { max-height: 500px; }
.max-height-450-px { max-height: 450px; }
.max-height-400-px { max-height: 400px; }
.max-height-350-px { max-height: 350px; }
.max-height-300-px { max-height: 300px; }
.max-height-250-px { max-height: 250px; }
.max-height-200-px { max-height: 200px; }
.max-height-150-px { max-height: 150px; }
.max-height-100-px { max-height: 100px; }
.max-height-95-px { max-height: 95px; }
.max-height-90-px { max-height: 90px; }
.max-height-85-px { max-height: 85px; }
.max-height-80-px { max-height: 80px; }
.max-height-75-px { max-height: 75px; }
.max-height-70-px { max-height: 70px; }
.max-height-65-px { max-height: 65px; }
.max-height-60-px { max-height: 60px; }
.max-height-55-px { max-height: 55px; }
.max-height-50-px { max-height: 50px; }
.max-height-45-px { max-height: 45px; }
.max-height-40-px { max-height: 40px; }
.max-height-35-px { max-height: 35px; }
.max-height-30-px { max-height: 30px; }
.max-height-25-px { max-height: 25px; }
.max-height-22-px { max-height: 22px; }
.max-height-20-px { max-height: 20px; }
.max-height-15-px { max-height: 15px; }
.max-height-10-px { max-height: 10px; }
.max-height-5-px { max-height: 5px; }


.min-height-100 { min-height: 100%; }
.min-height-95 { min-height: 95%; }
.min-height-90 { min-height: 90%; }
.min-height-85 { min-height: 85%; }
.min-height-80 { min-height: 80%; }
.min-height-75 { min-height: 75%; }
.min-height-70 { min-height: 70%; }
.min-height-65 { min-height: 65%; }
.min-height-60 { min-height: 60%; }
.min-height-55 { min-height: 55%; }
.min-height-50 { min-height: 50%; }
.min-height-45 { min-height: 45%; }
.min-height-40 { min-height: 40%; }
.min-height-35 { min-height: 35%; }
.min-height-30 { min-height: 30%; }
.min-height-25 { min-height: 25%; }
.min-height-20 { min-height: 20%; }
.min-height-15 { min-height: 15%; }
.min-height-10 { min-height: 10%; }
.min-height-5 { min-height: 5%; }

.max-height-100 { max-height: 100%; }
.max-height-95 { max-height: 95%; }
.max-height-90 { max-height: 90%; }
.max-height-85 { max-height: 85%; }
.max-height-80 { max-height: 80%; }
.max-height-75 { max-height: 75%; }
.max-height-70 { max-height: 70%; }
.max-height-65 { max-height: 65%; }
.max-height-60 { max-height: 60%; }
.max-height-55 { max-height: 55%; }
.max-height-50 { max-height: 50%; }
.max-height-45 { max-height: 45%; }
.max-height-40 { max-height: 40%; }
.max-height-35 { max-height: 35%; }
.max-height-30 { max-height: 30%; }
.max-height-25 { max-height: 25%; }
.max-height-20 { max-height: 20%; }
.max-height-15 { max-height: 15%; }
.max-height-10 { max-height: 10%; }
.max-height-5 { max-height: 5%; }

.width-100 { width: 100%; }
.width-95 { width: 95%; }
.width-90 { width: 90%; }
.width-85 { width: 85%; }
.width-80 { width: 80%; }
.width-75 { width: 75%; }
.width-70 { width: 70%; }
.width-65 { width: 65%; }
.width-60 { width: 60%; }
.width-55 { width: 55%; }
.width-50 { width: 50%; }
.width-45 { width: 45%; }
.width-40 { width: 40%; }
.width-35 { width: 35%; }
.width-30 { width: 30%; }
.width-25 { width: 25%; }
.width-20 { width: 20%; }
.width-15 { width: 15%; }
.width-10 { width: 10%; }
.width-5 { width: 5%; }

.min-width-1000-px { min-width: 1000px; }
.min-width-800-px { min-width: 800px; }
.min-width-700-px { min-width: 700px; }
.min-width-600-px { min-width: 600px; }
.min-width-500-px { min-width: 500px; }
.min-width-450-px { min-width: 450px; }
.min-width-400-px { min-width: 400px; }
.min-width-350-px { min-width: 350px; }
.min-width-300-px { min-width: 300px; }
.min-width-250-px { min-width: 250px; }
.min-width-200-px { min-width: 200px; }
.min-width-150-px { min-width: 150px; }
.min-width-100-px { min-width: 100px; }
.min-width-95-px { min-width: 95px; }
.min-width-90-px { min-width: 90px; }
.min-width-85-px { min-width: 85px; }
.min-width-80-px { min-width: 80px; }
.min-width-75-px { min-width: 75px; }
.min-width-70-px { min-width: 70px; }
.min-width-65-px { min-width: 65px; }
.min-width-60-px { min-width: 60px; }
.min-width-55-px { min-width: 55px; }
.min-width-50-px { min-width: 50px; }
.min-width-45-px { min-width: 45px; }
.min-width-40-px { min-width: 40px; }
.min-width-35-px { min-width: 35px; }
.min-width-30-px { min-width: 30px; }
.min-width-25-px { min-width: 25px; }
.min-width-22-px { min-width: 22px; }
.min-width-20-px { min-width: 20px; }
.min-width-15-px { min-width: 15px; }
.min-width-10-px { min-width: 10px; }
.min-width-5-px { min-width: 5px; }

.width-800-px { width: 800px; }
.width-400-px { width: 400px; }
.width-200-px { width: 200px; }
.width-100-px { width: 100px; }
.width-95-px { width: 95px; }
.width-90-px { width: 90px; }
.width-85-px { width: 85px; }
.width-80-px { width: 80px; }
.width-75-px { width: 75px; }
.width-70-px { width: 70px; }
.width-65-px { width: 65px; }
.width-60-px { width: 60px; }
.width-55-px { width: 55px; }
.width-50-px { width: 50px; }
.width-45-px { width: 45px; }
.width-40-px { width: 40px; }
.width-35-px { width: 35px; }
.width-30-px { width: 30px; }
.width-25-px { width: 25px; }
.width-20-px { width: 20px; }
.width-15-px { width: 15px; }
.width-10-px { width: 10px; }
.width-5-px { width: 5px; }

.min-width-400 { min-width: 400px; }
.min-width-300 { min-width: 300px; }
.min-width-200 { min-width: 200px; }
.min-width-180 { min-width: 180px; }
.min-width-175 { min-width: 175px; }
.min-width-150 { min-width: 150px; }
.min-width-125 { min-width: 125px; }
.min-width-100 { min-width: 100px; }
.min-width-95 { min-width: 95px; }
.min-width-90 { min-width: 90px; }
.min-width-85 { min-width: 85px; }
.min-width-80 { min-width: 80px; }
.min-width-75 { min-width: 75px; }
.min-width-70 { min-width: 70px; }
.min-width-65 { min-width: 65px; }
.min-width-60 { min-width: 60px; }
.min-width-55 { min-width: 55px; }
.min-width-50 { min-width: 50px; }
.min-width-45 { min-width: 45px; }
.min-width-40 { min-width: 40px; }
.min-width-35 { min-width: 35px; }
.min-width-30 { min-width: 30px; }
.min-width-25 { min-width: 25px; }
.min-width-20 { min-width: 20px; }
.min-width-15 { min-width: 15px; }
.min-width-10 { min-width: 10px; }
.min-width-5 { min-width: 5px; }

.max-width-400 { max-width: 400px; }
.max-width-300 { max-width: 300px; }
.max-width-200 { max-width: 200px; }
.max-width-100 { max-width: 100px; }
.max-width-95 { max-width: 95px; }
.max-width-90 { max-width: 90px; }
.max-width-85 { max-width: 85px; }
.max-width-80 { max-width: 80px; }
.max-width-75 { max-width: 75px; }
.max-width-70 { max-width: 70px; }
.max-width-65 { max-width: 65px; }
.max-width-60 { max-width: 60px; }
.max-width-55 { max-width: 55px; }
.max-width-50 { max-width: 50px; }
.max-width-45 { max-width: 45px; }
.max-width-40 { max-width: 40px; }
.max-width-35 { max-width: 35px; }
.max-width-30 { max-width: 30px; }
.max-width-25 { max-width: 25px; }
.max-width-20 { max-width: 20px; }
.max-width-15 { max-width: 15px; }
.max-width-10 { max-width: 10px; }
.max-width-5 { max-width: 5px; }

.borderTop { border-top: 1px solid $grey; }
.borderRight { border-right: 1px solid $grey; }
.borderBottom { border-bottom: 1px solid $grey; }
.borderLeft { border-left: 1px solid $grey; }
.ellipsis { text-overflow: ellipsis; }
.overflowHidden { overflow: hidden; }
.text-align-center { text-align: center; }
.text-align-left { text-align: left; }
.text-align-right { text-align: right; }

.fullBorder {
  border: 1px solid $grey;
  border-radius: 2px;
}

.border-bottom-1 {
  border-bottom: 1px solid $lightGrey;
}

.align-right { float: right; }
.align-left { float: left; }
.align-bottom { vertical-align: bottom; }

.pos-relative { position: relative; }
.pos-absolute { position: absolute; }

.float-left { float: left; }
.float-right { float: right; }
.clear-both { clear: both; }

.display-inline {
    display: inline;
    float: left;
}

.display-flex {
  display: flex;
}

.flex-items-center {
  align-items: center;
}

.overflow-hidden {
  overflow: hidden;
}

.center {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.display-inline-block {
    display: inline-block;
}

.inner {
    display: table-cell;
    vertical-align: middle;

    &.margin-bottom {
        margin-bottom: 40px;
    }
}

@keyframes fadeInOpacity {
    0% {  opacity: 0; }
    100% { opacity: 1; }
}


.border-bottom { border-bottom: 1px solid $lightGrey; }

.offShade {
    background-color:rgb(247, 247, 247);
    border-radius: 5px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: green;
    margin: 4px 2px 0px 0px;

    &.table {
      margin: 0px;
    }

    &.background-color-black { background-color: $black; }
    &.background-color-cyan { background-color: $cyan; }
    &.background-color-magenta { background-color: $magenta; }
    &.background-color-yellow { background-color: $yellow; }
    &.background-color-grey { background-color: $grey; }
}

.circleProduct {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: auto;

  &.green { background-color: green; }
  &.orange {  background-color: orange; }
  &.purple {  background-color: purple; }
  &.batched {  background-color: #89AE51; }
  &.royalblue {  background-color: royalblue; }
}

.pointer {
    cursor: pointer;
}

.transition-column {
  transition: 0.5s;
  &.collapse {
    height: 100px;
  }
  &.expand {
    height: 200px;
  }
}

.transition-child {
  grid-template-columns: 50px 10vw 100px;
  background-color: red;
  height: 130px;
  transition: 2s;
}

.hidden {
  visibility: hidden;
}

.border-radius-1 { border-radius: 1px; }
.border-radius-2 { border-radius: 2px; }
.border-radius-3 { border-radius: 3px; }
.border-radius-4 { border-radius: 4px; }
.border-radius-5 { border-radius: 5px; }
.border-radius-6 { border-radius: 6px; }
.border-radius-7 { border-radius: 7px; }
.border-radius-8 { border-radius: 8px; }
.border-radius-9 { border-radius: 9px; }
.border-radius-10 { border-radius: 10px; }
