.text-white {
    color: white;
}

.text-green {
    color: #00b200;
}

a {
    text-decoration: underline;
    cursor: pointer;
    &:hover
    {
        color: lighten($primary,100);
    }
}

h1 {
    color: $primary;
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 10px;
}

h2 {
    color: $primary;
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin-bottom: 8px;
}

h3 {
    color: $primary;
    margin: 0;
    font-size: 18px;
    line-height: 18px;
    padding: 0;
    margin-bottom: 6px;
}

h4 {
  color: $primary;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  margin-bottom: 4px;
}

h5 {
    margin: 0px;
    padding: 0px;
}

p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.no-wrap {
    white-space: nowrap;
}

.text-right { text-align: right; }
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-xxsmall { font-size: 8px; }
.text-xsmall { font-size: 10px; }
.text-small { font-size: 12px; }
.text-medium { font-size: 14px; }
.text-large { font-size: 18px; }
.text-xlarge { font-size: 20px; }
.text-xlarge-count { font-size: 50px; }


.text-4 { font-size: 4px; }
.text-6 { font-size: 6px; }
.text-8 { font-size: 8px; }
.text-10 { font-size: 10px; }
.text-11 { font-size: 11px; }
.text-12 { font-size: 12px; }
.text-13 { font-size: 13px; }
.text-14 { font-size: 14px; }
.text-15 { font-size: 15px; }
.text-16 { font-size: 16px; }
.text-17 { font-size: 17px; }
.text-18 { font-size: 18px; }
.text-19 { font-size: 19px; }
.text-20 { font-size: 20px; }
.text-22 { font-size: 22px; }
.text-24 { font-size: 24px; }
.text-26 { font-size: 25px; }

.box-shadow-10 { box-shadow: 0px 0px 4px 2px lightGrey };


.font-weight-normal { font-weight: normal; }
.font-weight-bold { font-weight: bold; }