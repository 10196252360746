.subNavHolder {
    width: 100%;
    border-bottom: 3px solid $primary;
    text-align: center;

    .subNav {
        width: auto;
        display: inline-block;
        margin-right: 4px;
        margin-left: 4px;
        background-color: #A9A9A9;
        color: white;
        padding: 6px 12px;
        font-size: 13px;
        border-radius: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        user-select: none;
        cursor: pointer;
        @include transition(background-color 0.2s ease);
        
        &:hover
        {
            background-color: $primary;
        }

        :not(.disabled) {
            &:hover {
                background-color: darken(#A9A9A9, 10%);
            }
        }

        &.disabled {
            cursor: not-allowed;
        }

        &.selected {
            background-color: $primary;
        }
    }
}